import React from 'react';
import DataTable from './DataTable';

const App = () => {
	return (
		<div>
			<h1 style = {{textAlign: 'center', fontWeight: '700'}}>Best Yield for Bitcoin</h1>
			<h3 style = {{textAlign: 'center'}}>Earn the highest yield in Defi on your Bitcoin</h3>
			<DataTable />
			
		</div>
		);
}

export default App


