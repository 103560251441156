import React from "react";
import styles from "./Styles/DataTable.css";
import Data from "./collection.json";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import TextField from "@mui/material/TextField";
import { MultiSelect } from "react-multi-select-component";
import { useState } from "react";



class DataTable extends React.Component {
  constructor(props) {
    super(props);
    this.previousIndex = 0;
    this.nextIndex = 20;
    this.indexCount = 20;
    this.listLength = 0;
    this.state = {
      active: 'today_yield_apy',
      direction: 'desc',
      dataLoaded: false,
      renderTokenList: [],
      ogdata: [],
      chainsfilter:[],
      Namefilter: ''
    };
    this.filteredData = [];
    this.tokenList = [];
    this.existingNameSearch = "";

      this.chains = [
      {label: "Ethereum",image: "./src/images/ethereum.png", value: "Ethereum",},
      { label: "BSC", image: "./src/images/bsc.png", value: "BSC" },
      { label: "Solana", image: "./src/images/solana.png", value: "Solana" },
      { label: "Polygon", image: "./src/images/polygon.png", value: "Polygon" },
      { label: "Terra", image: "./src/images/terra.png", value: "Terra" },
      { label: "Avax", image: "./src/images/avalanche.png", value: "Avax" },
      { label: "Fantom", image: "./src/images/fantom.png", value: "Fantom" },
      {
        label: "Arbitrum",
        image: "./src/images/arbitrum.png",
        value: "Arbitrum",
      },
      {
        label: "Defichain",
        image: "./src/images/defichain.png",
        value: "Defichain",
      },
      { label: "Celo", image: "./src/images/celo.png", value: "Celo" },
      { label: "Cronos", image: "./src/images/cronos.png", value: "Cronos" },
      { label: "Fuse", image: "./src/images/fuse.png", value: "Fuse" },
      { label: "Kusama", image: "./src/images/kusama.png", value: "Kusama" },
  
      { label: "Elrond", image: "./src/images/elrond.png", value: "Elrond" },
      { label: "Waves", image: "./src/images/waves.png", value: "Waves" },
      {
        label: "Moonbeam",
        image: "./src/images/moonbeam.png",
        value: "Moonbeam",
      },
      { label: "Metis", image: "./src/images/metis.png", value: "Metis" },
      { label: "Osmosis", image: "./src/images/osmosis.png", value: "Osmosis" },
      { label: "Harmony", image: "./src/images/harmony.png", value: "Harmony" },
      { label: "xDai", image: "./src/images/xdai.png", value: "xDai" },
      { label: "OKex", image: "./src/images/okex.png", value: "OKex" },
      { label: "Heco", image: "./src/images/heco.png", value: "Heco" },
      { label: "Aurora", image: "./src/images/aurora.png", value: "Aurora" },
      { label: "Boba", image: "./src/images/boba.png", value: "Boba" },
      {
        label: "Moonriver",
        image: "./src/images/moonriver.png",
        value: "Moonriver",
      },
      { label: "Astar", image: "./src/images/astar.png", value: "Astar" },
      { label: "Shiden", image: "./src/images/shiden.png", value: "Shiden" },
      {
        label: "Optimism",
        image: "./src/images/optimism.png",
        value: "Optimism",
      },
    ];
    // We are collecting the python generated json file to get all the data using the fetch api. Importing the file directly doesn't work in build.
  }
  
  componentDidMount() {
    fetch("/collection.json").then((res) => {
      res.json().then((data) => {
        this.setState({ ogdata: data });
        this.tokenList = this.converDataToDataTable(data);
        this.setState({renderTokenList: this.tokenList.slice(this.previousIndex,this.nextIndex)});
  });});}




  converDataToDataTable(data) {
    if (this.state.direction === "asc"){
      return data.sort(this.GetSortOrder(this.state.active)).map((token, index) => {
        if(token.today_tvl > 14999){



          if (this.state.Namefilter !== "") {
              if (token.name.toUpperCase().includes(this.state.Namefilter.toUpperCase())){
                
                if (this.state.chainsfilter.length !== 0){
                  var found = false
              this.state.chainsfilter.map((chain, index) => {
                if(chain.label.toUpperCase() == token.provider.chain.toUpperCase() ){found = true}
              })
              if (found){
                if ((token.today_yield_apy !== null) & (token.today_yield_apy !== 0)) {
                  this.listLength += 1;}
              return this.initializeList(token)}
                } else {if ((token.today_yield_apy !== null) & (token.today_yield_apy !== 0)) {
                  this.listLength += 1;}
                  return this.initializeList(token)}
                }
                
            }else{ if (this.state.chainsfilter.length !== 0){
              var found = false
              this.state.chainsfilter.map((chain, index) => {
                if(chain.label.toUpperCase() == token.provider.chain.toUpperCase() ){found = true}
              })
              if (found){if ((token.today_yield_apy !== null) & (token.today_yield_apy !== 0)) {
                this.listLength += 1;}
              return this.initializeList(token)}
            } else {if ((token.today_yield_apy !== null) & (token.today_yield_apy !== 0)) {
              this.listLength += 1;}
              return this.initializeList(token)}}
       }
        


    });}else if (this.state.direction === "desc"){
      return data.sort(this.GetSortOrder(this.state.active)).reverse().map((token, index) => {
        if(token.today_tvl > 14999){
          
            if (this.state.Namefilter !== "") {
              if (token.name.toUpperCase().includes(this.state.Namefilter.toUpperCase())){
                
                if (this.state.chainsfilter.length !== 0){

                  var found = false
              this.state.chainsfilter.map((chain, index) => {
                if(chain.label.toUpperCase() == token.provider.chain.toUpperCase() ){found = true}
              })
              if (found){
                if ((token.today_yield_apy !== null) & (token.today_yield_apy !== 0)) {
                  this.listLength += 1;}
              return this.initializeList(token)}

                } else {if ((token.today_yield_apy !== null) & (token.today_yield_apy !== 0)) {
                  this.listLength += 1;}
                  return this.initializeList(token)}
                }
                
            }else{ if (this.state.chainsfilter.length !== 0){
              var found = false
              this.state.chainsfilter.map((chain, index) => {
                if(chain.label.toUpperCase() == token.provider.chain.toUpperCase() ){found = true}
              })
              if (found){
                if ((token.today_yield_apy !== null) & (token.today_yield_apy !== 0)) {
                  this.listLength += 1;}
              return this.initializeList(token)}
            } else {if ((token.today_yield_apy !== null) & (token.today_yield_apy !== 0)) {
              this.listLength += 1;}
              return this.initializeList(token)}}

      }
    });}
  }



  decimalCheck(number) {if (number > 100) {return Math.round(number);} 
    else {return number}
  }

  GetSortOrder(prop) {
    return function (a, b) {
      if (a[prop] > b[prop]) {
        return 1;
      }else if (a[prop] < b[prop]) {
        return -1;
      }
      return 0;
    };
  }

  initializeList = (token) => {
    return (
      <tr key={token.id}>
        <td>
          {(token.icon == '')?null:<div className="inline" style={{ minWidth: "80px" }}>
            <img className="DataTable image"
              src={`https://farm.army${token.icon}`}
              alt="" ></img>
          </div>}
          
          <div className="inline">
            <p>{token.name}</p>
            <a href={token.provider.url}>
              <p>{token.provider.label}</p>
            </a>
          </div>
        </td>
        <td className="block center">
          <strong>
            {Math.round(token.today_tvl)?.toLocaleString() !== 0
              ? "$" + Math.round(token.today_tvl)?.toLocaleString()
              : null}
          </strong>
        </td>
        <td className="block center">
          {Math.round(token.seven_day_tvl)?.toLocaleString() !== 0
            ? "$" + Math.round(token.seven_day_tvl)?.toLocaleString()
            : null}
        </td>
        <td className="block center">
          {Math.round(token.thirty_day_tvl)?.toLocaleString() !== 0
            ? "$" + Math.round(token.thirty_day_tvl)?.toLocaleString()
            : null}
        </td>
        <td className="block center">
          <div>
            <strong>
              {this.decimalCheck(token.today_yield_apy)?.toLocaleString() !== 0
                ? this.decimalCheck(token.today_yield_apy)?.toLocaleString() +
                  "%"
                : ""}
            </strong>
          </div>
          <div>
            {this.decimalCheck(token.today_yield)?.toLocaleString() !== 0
              ? this.decimalCheck(token.today_yield)?.toLocaleString() + "%"
              : ""}
          </div>
        </td>
        <td className="block center">
          <div>
            <strong>
              {this.decimalCheck(
                token.seven_day_yield_apy
              )?.toLocaleString() !== 0
                ? this.decimalCheck(
                    token.seven_day_yield_apy
                  )?.toLocaleString() + "%"
                : ""}
            </strong>
          </div>
          <div>
            {this.decimalCheck(token.seven_day_yield)?.toLocaleString() !== 0
              ? this.decimalCheck(token.seven_day_yield)?.toLocaleString() + "%"
              : ""}
          </div>
        </td>
        <td className="block center">
          <div>
            <strong>
              {this.decimalCheck(
                token.thirty_day_yield_apy
              )?.toLocaleString() !== 0
                ? this.decimalCheck(
                    token.thirty_day_yield_apy
                  )?.toLocaleString() + "%"
                : ""}
            </strong>
          </div>
          <div>
            {this.decimalCheck(token.thirty_day_yield)?.toLocaleString() !== 0
              ? this.decimalCheck(token.thirty_day_yield)?.toLocaleString() +
                "%"
              : ""}
          </div>
        </td>
        <td>
          {token.earn.map((value) => (
            <a href={token.link}>
              <img src={`https://farm.army${value.icon}`} alt=""></img>
            </a>
          ))}
        </td>
        <td>
          {(token.compound === true) | (token.cryptoLabel.length > 0) ? (
            <div style={{ paddingBottom: "15px", textAlign: "left" }}>
              {token.compound === true ? (
                <div>
                  <span className="info blue">auto compound</span>
                </div>
              ) : null}
              {token.cryptoLabel !== []
                ? token.cryptoLabel.map((val) =>
                    (val.label === "deprecated") |
                    (val.label === "lend") |
                    (val.label === "borrow") ? (
                      <div>
                        <span className={`info ${val.label}`}>{val.label}</span>
                      </div>
                    ) : null
                  )
                : null}
            </div>
          ) : null}
          <a className="center dataTableProviderLink" href={token.provider.url}>
            <img
              className="DataTable image"
              src={`https://farm.army${token.provider.icon}`}
              alt=""
            ></img>
            <img
              className="DataTable smallImage"
              src={`./src/images/${token.provider.chain}.png`}
              alt=""
            ></img>
          </a>
        </td>
      </tr>);};





  displayItems(){
        this.tokenList = this.converDataToDataTable(this.state.ogdata);
        this.tokenList = this.tokenList.filter(function( element ) {
          return element !== undefined;
        });

        this.setState({
          renderTokenList: this.tokenList.slice(
            this.previousIndex,
            this.nextIndex
          ),
        })}





  previousPage = () => {
    if (this.previousIndex - this.indexCount < 0) {
      this.previousIndex = 0;
      this.nextIndex = this.indexCount;
      this.setState({renderTokenList: this.tokenList.slice(this.previousIndex, this.nextIndex)})
    }else{
        this.previousIndex -= this.indexCount;
        this.nextIndex -= this.indexCount;
        this.setState({renderTokenList: this.tokenList.slice(this.previousIndex, this.nextIndex)})}}


  nextPage = () => {
    if ((this.nextIndex + this.indexCount) > this.listLength) {
      this.setState({renderTokenList: this.tokenList.slice(this.previousIndex, this.nextIndex)})
      }
     else {
      this.previousIndex += this.indexCount;
      this.nextIndex += this.indexCount;
      this.setState({renderTokenList: this.tokenList.slice(this.previousIndex, this.nextIndex)})
    }
  };


  toggleArrowstate(){
    if (this.state.direction == 'desc'){
      this.setState({direction: 'asc'})
    }else if(this.state.direction == 'asc'){
      this.setState({direction: 'desc'})
    }
  }


  render() {
    if (this.state.renderTokenList !== true && this.state.data !== []) {
      return (
        <div className="DataTable">
          <div
            className="DataTableIconContainer"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <div style={{display:'flex'}}>
              <TextField
                id="outlined-basic"
                label="Name"
                inputProps={{
                  style: {
                    height: "10px",
                  },
                }}
                InputLabelProps={{
                  style: {
                    fontSize: 14,
                    backgroundColor: "#FFF",
                    paddingLeft: 4,
                    paddingRight: 4,
                    color: "#383838",
                  },
                }}
                variant="outlined"
                value = {this.state.Namefilter}
                onChange = {(e) => {
                  this.setState({Namefilter:e.target.value},this.displayItems)
                  }
                }
              />

<MultiSelect
        options={this.chains}
        value={this.state.chainsfilter}
        onChange={(e) => {
          this.setState({chainsfilter:e},this.displayItems);
        }}
        labelledBy="Select"/>

            </div>
            <div>
              <button className="dataTableIcon" onClick={this.previousPage}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="25"
                  fill="currentColor"
                  className="bi bi-arrow-left-square-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M16 14a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12zm-4.5-6.5H5.707l2.147-2.146a.5.5 0 1 0-.708-.708l-3 3a.5.5 0 0 0 0 .708l3 3a.5.5 0 0 0 .708-.708L5.707 8.5H11.5a.5.5 0 0 0 0-1z" />
                </svg>
              </button>
              <button className="dataTableIcon" onClick={this.nextPage}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="25"
                  fill="currentColor"
                  className="bi bi-arrow-right-square-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M0 14a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2v12zm4.5-6.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5a.5.5 0 0 1 0-1z" />
                </svg>
              </button>
            </div>
          </div>
          <div>
            <div>
              <table className="table table-responsive table-hover">
                <thead>
                  <tr>
                    <th>Name:</th>
                    

                    <th className="center">
                      <div
                        style={{ display: "flex", cursor: "pointer" }}
                        onClick={() => {
                          this.toggleArrowstate();
                          this.setState({active:'today_tvl'},this.displayItems)
                        }}
                      >
                        <div style={{ margin: "auto" }}>Today TVL:</div>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <ArrowDropUpIcon
                            style={{
                              color: "black",
                              display: (this.state.active == 'today_tvl' && this.state.direction == 'desc')
                                ? "block"
                                : "none",
                            }}
                          />
                          <ArrowDropDownIcon
                            style={{
                              color: "black",
                              display: (this.state.active == 'today_tvl' && this.state.direction == 'asc')
                                ? "block"
                                : "none",
                            }}
                          />{" "}
                        </div>
                      </div>
                    </th>
                    {/*SEVEN_DAY_TVL HEADER*/}
                    <th className="center">
                      <div
                        style={{ display: "flex", cursor: "pointer" }}
                        onClick={() => {
                          this.toggleArrowstate();
                          this.setState({active:'seven_day_tvl'},this.displayItems)
                        
                        }}
                      >
                        <div style={{ margin: "auto" }}>7d ago:</div>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <ArrowDropUpIcon
                            style={{
                              color: "black",
                              display: (this.state.active == 'seven_day_tvl' && this.state.direction == 'desc')
                                ? "block"
                                : "none",
                            }}
                          />{" "}
                          <ArrowDropDownIcon
                            style={{
                              color: "black",
                              display: (this.state.active == 'seven_day_tvl' && this.state.direction == 'asc')
                                ? "block"
                                : "none",
                            }}
                          />{" "}
                        </div>
                      </div>
                    </th>

                    {/*THIRTY_DAY_TVL HEADER*/}

                    <th className="center">
                      <div
                        style={{ display: "flex", cursor: "pointer" }}
                        onClick={() => {
                          this.toggleArrowstate();
                          this.setState({active:'thirty_day_tvl'},this.displayItems)
                        }}
                      >
                        <div style={{ margin: "auto" }}> 30d ago:</div>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <ArrowDropUpIcon
                            style={{
                              color: "black",
                              display: (this.state.active == 'thirty_day_tvl' && this.state.direction == 'desc')
                                ? "block"
                                : "none",
                            }}
                          />{" "}
                          <ArrowDropDownIcon
                            style={{
                              color: "black",
                              display: (this.state.active == 'thirty_day_tvl' && this.state.direction == 'asc')
                                ? "block"
                                : "none",
                            }}
                          />{" "}
                        </div>
                      </div>
                    </th>

                    {/*TODAY_YIELD HEADER*/}
                    <th className="center">
                      <div
                        style={{ display: "flex", cursor: "pointer" }}
                        onClick={() => {
                          this.toggleArrowstate();
                          this.setState({active:'today_yield_apy'},this.displayItems)
                        }}
                      >
                        <div style={{ margin: "auto" }}> Today Yield:</div>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <ArrowDropUpIcon
                            style={{
                              color: "black",
                              display: (this.state.active == 'today_yield_apy' && this.state.direction == 'desc')
                                ? "block"
                                : "none",
                            }}
                          />
                          <ArrowDropDownIcon
                            style={{
                              color: "black",
                              display: (this.state.active == 'today_yield_apy' && this.state.direction == 'asc')
                                ? "block"
                                : "none",
                            }}
                          />
                        </div>
                      </div>
                    </th>
                    {/*SEVEN_DAY_YIELD_APY HEADER*/}
                    <th className="center">
                      <div
                        style={{ display: "flex", cursor: "pointer" }}
                        onClick={() => {
                          this.toggleArrowstate();
                          this.setState({active:'seven_day_yield_apy'},this.displayItems)
                        }}
                      >
                        <div style={{ margin: "auto" }}> 7d ago:</div>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <ArrowDropUpIcon
                            style={{
                              color: "black",
                              display: (this.state.active == 'seven_day_yield_apy' && this.state.direction == 'desc')
                                ? "block"
                                : "none",
                            }}
                          />
                          <ArrowDropDownIcon
                            style={{
                              color: "black",
                              display: (this.state.active == 'seven_day_yield_apy' && this.state.direction == 'asc')
                                ? "block"
                                : "none",
                            }}
                          />{" "}
                        </div>
                      </div>
                    </th>

                    {/*THIRTY_DAY_YIELD_HEADER HEADER*/}
                    <th className="center">
                      <div
                        style={{ display: "flex", cursor: "pointer" }}
                        onClick={() => {
                          this.toggleArrowstate();
                          this.setState({active:'thirty_day_yield_apy'},this.displayItems)
                        }}
                      >
                        <div style={{ margin: "auto" }}> 30d ago:</div>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <ArrowDropUpIcon
                            style={{
                              color: "black",
                              display: (this.state.active == 'thirty_day_yield_apy' && this.state.direction == 'desc')
                                ? "block"
                                : "none",
                            }}
                          />
                          <ArrowDropDownIcon
                            style={{
                              color: "black",
                              display: (this.state.active == 'thirty_day_yield_apy' && this.state.direction == 'asc')
                                ? "block"
                                : "none",
                            }}
                          />{" "}
                        </div>
                      </div>
                    </th>
                    <th>Earn:</th>
                    <th>Provider:</th>
                  </tr>
                </thead>
                <tbody>{this.state.renderTokenList}</tbody>
              </table>
            </div>
          </div>
          <div className="DataTableIconContainer bottom">
            <button className="dataTableIcon" onClick={this.previousPage}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                fill="currentColor"
                className="bi bi-arrow-left-square-fill"
                viewBox="0 0 16 16"
              >
                <path d="M16 14a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12zm-4.5-6.5H5.707l2.147-2.146a.5.5 0 1 0-.708-.708l-3 3a.5.5 0 0 0 0 .708l3 3a.5.5 0 0 0 .708-.708L5.707 8.5H11.5a.5.5 0 0 0 0-1z" />
              </svg>
            </button>
            <button className="dataTableIcon" onClick={this.nextPage}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                fill="currentColor"
                className="bi bi-arrow-right-square-fill"
                viewBox="0 0 16 16"
              >
                <path d="M0 14a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2v12zm4.5-6.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5a.5.5 0 0 1 0-1z" />
              </svg>
            </button>
          </div>
        </div>
      );
    }

    return "Loading...";
  }
}

export default DataTable;
